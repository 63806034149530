/* eslint-disable react/jsx-key */
import React, { PureComponent } from 'react';
import { List, Datagrid, TextField, BooleanField, EditButton, FunctionField, TextInput, CheckboxGroupInput } from 'react-admin';
import { ColorField } from 'react-admin-color-input';

import CustomPagination from '../common/CustomPagination';

const ACTIVITY_SOURCES = {
  name: 'name',
  key: 'key',
  isActive: 'isActive',
  type: 'type',
  color: 'color',
};

const TYPE_TO_NAME_MAP = {
  project: 'Project',
  internalProject: 'Internal Project',
  timeOff: 'Time Off',
};

const acitivityFilters = [
  <TextInput label="Name" source={ACTIVITY_SOURCES.name} />,
  <TextInput label="Short Name" source={ACTIVITY_SOURCES.key} />,
  <CheckboxGroupInput
    source={ACTIVITY_SOURCES.isActive}
    choices={[
      { id: '1', name: 'True' },
      { id: '0', name: 'False' },
    ]}
  />,
  <CheckboxGroupInput
    source={ACTIVITY_SOURCES.type}
    choices={[
      { id: 'project', name: TYPE_TO_NAME_MAP.project },
      { id: 'internalProject', name: TYPE_TO_NAME_MAP.internalProject },
      { id: 'timeOff', name: TYPE_TO_NAME_MAP.timeOff },
    ]}
  />,
];

class ActivityList extends PureComponent {
  renderType = record => {
    return TYPE_TO_NAME_MAP[record.type];
  };

  render() {
    return (
      <List
        {...this.props}
        sort={{ field: 'name', order: 'DESC' }}
        pagination={<CustomPagination />}
        bulkActionButtons={false}
        filters={acitivityFilters}
      >
        <Datagrid>
          <TextField source={ACTIVITY_SOURCES.name} />
          <TextField label="Short Name" source={ACTIVITY_SOURCES.key} />
          <BooleanField source={ACTIVITY_SOURCES.isActive} />
          <FunctionField label="Type" source={ACTIVITY_SOURCES.type} render={this.renderType} />
          <ColorField label="Color" source={ACTIVITY_SOURCES.color} />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}

export default ActivityList;
