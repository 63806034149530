export default theme => ({
  footerContainer: {
    zIndex: 999,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'rgba(255,255,255,0.9)',
    boxShadow: '0px -5px 10px 0px rgba(66, 68, 90, 0.1)',
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px',
  },
  footerPlaceholder: {
    // eslint-disable-next-line no-magic-numbers
    marginTop: theme.spacing(3),
  },
});
