import {
  MODAL_HIDE,
  MODAL_SHOW,
} from '../constants/action-types';

export const initialState = {
  visible: false,
  type: null,
  title: '',
  body: '',
  modalKey: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_SHOW:
      return {
        ...state,
        visible: true,
        type: action.payload.type,
        title: action.payload.title,
        body: action.payload.body,
        modalKey: action.payload.modalKey,
      };
    case MODAL_HIDE:
      return {
        ...state,
        visible: false,
        modalKey: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
