import {
  List,
  Datagrid,
  DeleteButton,
  TextField,
  EmailField,
  DateField,
} from 'react-admin';
import React from 'react';

import CustomPagination from '../common/CustomPagination';

const InfiniteTokenList = props => {
  return (
    <List {...props} bulkActionButtons={false} pagination={<CustomPagination />}>
      <Datagrid>
        <TextField label="First name" source="user.firstname" />
        <TextField label="Last name" source="user.lastname" />
        <EmailField label="Email" source="user.email" />
        <DateField label="Created at" source="createdAt" showTime/>
        <DeleteButton />
      </Datagrid>
    </List>);
};

export default InfiniteTokenList;
