export default {
  copyrightEntry: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },

  copyrightEntryName: {
    fontWeight: 'bold',
  },

  contentPreview: {
    whiteSpace: 'pre-line',
    margin: '10px 0',
  },

  horizontalSpacer: {
    flex: 1,
  },

  buttonsContainer: {
    marginLeft: '20px',
  },

  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
};
