import React from 'react';
import { Create, BooleanInput, TextInput, SimpleForm, RadioButtonGroupInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import { validateName, validateKey, validateType } from '../../helpers/validators/activity';

const ActivityCreate = props => (
  <Create title="Create Activity" {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={validateName} />
      <TextInput label="Short Name" source="key" validate={validateKey} />
      <BooleanInput label="Active" source="isActive" />
      <RadioButtonGroupInput
        validate={validateType}
        defaultValue="project"
        source="type"
        choices={[
          { id: 'project', name: 'Project' },
          { id: 'internalProject', name: 'Internal Project' },
          { id: 'timeOff', name: 'Time Off' },
        ]}
      />
      <ColorInput label="Color" source="color" />
    </SimpleForm>
  </Create>
);

export default ActivityCreate;
