import { GET_ONE } from 'react-admin';

import { dataProvider } from '../App';

const checkEmailAvailability = async email => {
  try {
    await dataProvider(
      /* Use GET_ONE here and send the email as id */
      GET_ONE,
      'verifyEmailIsNotUsed',
      /* Attach random id so it does not throw error */
      { id: email },
    );

    return true;
  } catch (err) {
    /* If there was an error email is taken */
    return false;
  }
};

export default checkEmailAvailability;
