import React from 'react';
import { connect } from 'react-redux';
import { SaveButton, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';

import Modal from '../../containers/modal/ModalContainer';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_CONFIRM } from '../../constants/modal-types';

class UpdateToolbar extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    handleSubmitWithRedirect: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    children: PropTypes.arrayOf(PropTypes.func),
  };

  onUpdateClick = () => {
    const formIsValid = !this.props.invalid;
    if (formIsValid) {
      this.props.showModal(
        MODAL_TYPE_CONFIRM,
        'Confirm Edit',
        'Do you really want to edit this resource?',
      );
    }
    return false;
  };

  onConfirm = () => {
    this.props.handleSubmitWithRedirect();
  };

  render() {
    return (
      <Toolbar>
        <SaveButton
          submitOnEnter
          handleSubmitWithRedirect={this.onUpdateClick}
        />
        <Modal confirm={this.onConfirm} />
        {this.props.children}
      </Toolbar>
    );
  }
}

export default connect(null, {
  showModal,
})(UpdateToolbar);

export const UnwrappedComponent = UpdateToolbar;
