import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  MODAL_TYPE_CONFIRM,
} from '../../constants/modal-types';
import {
  hideModal,
} from '../../actions/modalActions';

import ConfirmModal from './modals/ConfirmModal';

const MODAL_COMPONENTS = {
  [MODAL_TYPE_CONFIRM]: ConfirmModal,
};

export const ModalRoot = props => {
  if (!props.modalType || !props.visible
    || (props.modalKey && props.modalKey !== props.id)) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[props.modalType];
  return (
    <SpecificModal
      {...props}
      hideModal={props.hideModal}
    />
  );
};

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  visible: PropTypes.bool,
  hideModal: PropTypes.func,
  modalKey: PropTypes.string,
  id: PropTypes.string,
};

export default connect(store => ({
  modalType: store.modal.type,
  visible: store.modal.visible,
  title: store.modal.title,
  body: store.modal.body,
  modalKey: store.modal.modalKey,
}),
{
  hideModal,
})(ModalRoot);
