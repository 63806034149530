/* eslint-disable react/jsx-no-bind */
import React, { PureComponent } from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin';

import UpdateToolbar from '../common/UpdateToolbar';
import { validateDate, validateUser } from '../../helpers/validators/reopenMonth';

class UserEdit extends PureComponent {
  render() {
    return (
      <Edit title="Edit User" {...this.props}>
        <SimpleForm redirect="list" toolbar={<UpdateToolbar />}>
          <ReferenceInput
            perPage={200}
            label="User"
            source="user.id"
            reference="user"
            validate={validateUser}
            sort={{
              field: 'firstname',
              order: 'ASC',
            }}
            filter={{
              isActive: 1,
            }}
          >
            <SelectInput optionText={user => `${user.firstname} ${user.lastname}`} />
          </ReferenceInput>
          <DateInput label="Reopen Month" source="date" validate={validateDate}/>
        </SimpleForm>
      </Edit>
    );
  }
}

export default UserEdit;
