import React from 'react';
import { Edit, SimpleForm, DateInput, required } from 'react-admin';

import UpdateToolbar from '../common/UpdateToolbar';

const inputValidation = required('This property is required');

const IpBoxConfigurationEdit = props => (
  <Edit title="Edit IP Box Configuration" {...props}>
    <SimpleForm toolbar={<UpdateToolbar />} redirect="list">
      <DateInput
        source="readonlyBeforeDate"
        validate={inputValidation}
        label="IP Box read-only before"
        helperText="The day value does not matter - choose only month and year, and all IP Box forms before that date will be read-only"
      />
    </SimpleForm>
  </Edit>
);

export default IpBoxConfigurationEdit;
