/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin';

import { validateDate, validateUser } from '../../helpers/validators/reopenMonth';

export default class UserCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect="list">
          <ReferenceInput
            perPage={200}
            label="User"
            source="user"
            reference="user"
            validate={validateUser}
            sort={{
              field: 'firstname',
              order: 'ASC',
            }}
            filter={{
              isActive: 1,
            }}
          >
            <SelectInput optionText={user => `${user.firstname} ${user.lastname}`} />
          </ReferenceInput>
          <DateInput label="Reopen Month" source="date" validate={validateDate} />
        </SimpleForm>
      </Create>
    );
  }
}
