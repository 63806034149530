import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getResources, MenuItemLink } from 'react-admin';
import LabelIcon from '@material-ui/icons/ViewList';
import ActivityIcon from '@material-ui/icons/EventNote';
import UserIcon from '@material-ui/icons/Person';
import UserGroupIcon from '@material-ui/icons/Group';
import PublicHolidayIcon from '@material-ui/icons/Event';
import ReportsIcon from '@material-ui/icons/Assignment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const getItemIcon = resourceName => {
  const icons = {
    activity: <ActivityIcon />,
    user: <UserIcon />,
    userGroup: <UserGroupIcon />,
    publicHoliday: <PublicHolidayIcon />,
    infiniteTokens: <VpnKeyIcon />,
  };
  const defaultIcon = <LabelIcon />;
  return icons[resourceName] || defaultIcon;
};

const MyMenu = ({ resources, onMenuClick }) => (
  <div>
    {resources.map(resource => {
      if (typeof resource.options.show !== 'undefined'
          && !resource.options.show) {
        return null;
      }

      const name = typeof resource.options.label !== 'undefined'
        ? resource.options.label
        : resource.name[0].toUpperCase() + resource.name.slice(1);

      return (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={name}
          onClick={onMenuClick}
          leftIcon={getItemIcon(resource.name)}
          dense
        />
      );
    })}
    <MenuItemLink
      to="/reports"
      primaryText="Reports"
      onClick={onMenuClick}
      leftIcon={<ReportsIcon/>}
      dense
    />
  </div>
);

MyMenu.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({
    options: PropTypes.shape({
      show: PropTypes.boolean,
      label: PropTypes.string,
    }),
    name: PropTypes.string,
  })),
  onMenuClick: PropTypes.func,
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
