import React from 'react';
import { connect } from 'react-redux';
import { CardActions } from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Modal from '../../containers/modal/ModalContainer';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_CONFIRM } from '../../constants/modal-types';
import dataProvider from '../../providers/dataProvider';
import config from '../../config';

const styles = () => ({
  button: {
    color: 'rgb(225, 0, 80)',
    border: '1px solid rgba(225, 0, 80, 0.5)',
    padding: '8px 12px',
    fontSize: '15px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgb(225, 0, 80)',
      backgroundColor: 'rgba(225, 0, 80, 0.08)',
    },
  },
});

class UserEditActions extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }),
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  displayModal = () => {
    this.props.showModal(
      MODAL_TYPE_CONFIRM,
      'Confirm Action',
      'Do you really want to log out this user from all devices?',
      'logout',
    );
  };

  onConfirm = () => {
    dataProvider(config.backend.url)('CUSTOM', 'deleteTokens', {
      data: {
        userId: this.props.data.id,
      },
    });
  };

  render() {
    return (
      <CardActions>
        <button
          className={this.props.classes.button}
          onClick={this.displayModal}
        >
          Sign out from all sessions
        </button>
        <Modal id="logout" confirm={this.onConfirm} />
      </CardActions>
    );
  }
}

export default connect(null, {
  showModal,
})(withStyles(styles)(UserEditActions));

export const UnwrappedComponent = UserEditActions;
