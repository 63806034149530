/* eslint-disable react/jsx-key */
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  BooleanField,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  CheckboxGroupInput,
  regex,
  DateInput,
} from 'react-admin';

import dataProvider from '../../providers/dataProvider';
import CustomPagination from '../common/CustomPagination';
import config from '../../config';

const USER_SOURCES = {
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  phone: 'phone',
  location: 'location',
  isActive: 'isActive',
  timeFrom: 'timeFrom',
  timeTo: 'timeTo',
  groups: 'groups',
  expectedWorkTime: 'expectedWorkTime',
  name: 'name',
  firstReportingDate: 'firstReportingDate',
  firstReportingDateFrom: 'firstReportingDateFrom',
  firstReportingDateTo: 'firstReportingDateTo',
};

const validateNumberInput = [regex(/^[0-9]+$/, 'Only numbers are allowed')];

const prepareFilters = (errorOccurred, groups = []) => {
  if (errorOccurred) {
    return [];
  }

  const userGroupsChoices = groups.map(group => {
    return { id: group.name, name: group.name };
  });

  return [
    <TextInput label="First name" source={USER_SOURCES.firstname} />,
    <TextInput label="Last name" source={USER_SOURCES.lastname} />,
    <TextInput label="Email" source={USER_SOURCES.email} />,
    <TextInput label="Phone" source={USER_SOURCES.phone} validate={validateNumberInput} />,
    <TextInput label="Location" source={USER_SOURCES.location} />,
    <CheckboxGroupInput
      source="isActive"
      choices={[
        { id: '1', name: 'True' },
        { id: '0', name: 'False' },
      ]}
    />,
    <TextInput label="Work time from" source={USER_SOURCES.timeFrom} validate={validateNumberInput}/>,
    <TextInput label="Work time to" source={USER_SOURCES.timeTo} validate={validateNumberInput}/>,
    <CheckboxGroupInput
      source={USER_SOURCES.groups}
      choices={userGroupsChoices}
    />,
    <DateInput
      label="Reporting date from"
      source={USER_SOURCES.firstReportingDateFrom}
      type="date"
      style={{ minWidth: '160px' }}
    />,
    <DateInput
      label="Reporting date to"
      source={USER_SOURCES.firstReportingDateTo}
      type="date"
      style={{ minWidth: '160px' }}
    />,
  ];
};

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };
  }

  async componentDidMount() {
    try {
      const response = await dataProvider(config.backend.url)('CUSTOM', 'userGroup', {
        method: 'GET',
      });
      this.setState({
        filters: prepareFilters(false, response),
      });
    } catch (err) {
      this.setState({
        filters: prepareFilters(true),
      });
    }
  }

  render() {
    return (
      <List {...this.props} sort={{ field: 'isActive', order: 'DESC' }} pagination={<CustomPagination />} bulkActionButtons={false} filters={this.state.filters}>
        <Datagrid>
          <TextField label="First name" source={USER_SOURCES.firstname} />
          <TextField label="Last name" source={USER_SOURCES.lastname} />
          <EmailField source={USER_SOURCES.email} />
          <TextField source={USER_SOURCES.phone} />
          <NumberField label="Expected work time (minutes)" source={USER_SOURCES.expectedWorkTime} textAlign="left" />
          <TextField source={USER_SOURCES.location} />
          <ArrayField source={USER_SOURCES.groups} target="userGroup" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source={USER_SOURCES.name} />
            </SingleFieldList>
          </ArrayField>
          <BooleanField source={USER_SOURCES.isActive} label="Active"/>
          <NumberField label="First reporting date" source={USER_SOURCES.firstReportingDate} textAlign="left" />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}
