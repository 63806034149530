import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  DateInput,
  ReferenceArrayInput,
} from 'react-admin';
import { format } from 'date-fns';

import config from '../../config';
import CheckboxGroup from '../common/CheckboxGroup';
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
  validateExpectedWorkTime,
  validateDate,
  ensureEmailIsNotInUse,
  validateGroups,
} from '../../helpers/validators/user';

export default class UserCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect="list" asyncValidate={ensureEmailIsNotInUse}>
          <TextInput label="First name" source="firstname" validate={validateName} />
          <TextInput label="Last name" source="lastname" validate={validateName} />
          <TextInput source="email" validate={validateEmail} />
          <TextInput source="phone" validate={validatePhoneNumber} />
          <NumberInput defaultValue={480} label="Expected work time (minutes / day)" source="expectedWorkTime" validate={validateExpectedWorkTime} />
          <TextInput source="location" />
          <DateInput source="firstReportingDate" validate={validateDate} defaultValue={format(new Date(), 'yyyy-MM-dd')} />
          <ReferenceArrayInput reference="userGroup" source="groups" label="Groups" validate={validateGroups}>
            <CheckboxGroup forceSelect={{ selected: config.roles.admin, force: config.roles.user }}/>
          </ReferenceArrayInput>
          <BooleanInput label="Active" source="isActive" />
        </SimpleForm>
      </Create>
    );
  }
}
