import { Layout } from 'react-admin';
import React from 'react';

import { LAYOUT_ID } from '../../constants/element-selectors';

import Sidebar from './Sidebar';

const MyLayout = props => <Layout id={LAYOUT_ID} {...props} menu={Sidebar} />;

export default MyLayout;
