import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  EditButton,
} from 'react-admin';

import ReportEmailsField from './ReportEmailsField';

const ReportAlertConfigurationList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <NumberField label="Report Day" source="reportDay"/>
      <ReportEmailsField label="Report emails"/>
      <EditButton />
    </Datagrid>
  </List>
);


export default ReportAlertConfigurationList;
