import { required } from 'react-admin';

const validateName = [required()];
const validateKey = [required()];
const validateType = [required()];

export {
  validateName,
  validateKey,
  validateType,
};
