import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Title } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import dataProvider from '../../providers/dataProvider';
import config from '../../config';
import Alert from '../common/Alert';

import ReportItem from './ReportItem';
import Token from './Token';
import Filters from './SpreadsheetFilters';
import generators from './formulaGenerators';


const styles = {
  grayCard: {
    background: '#f3f3f3',
    padding: '20px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    marginLeft: '10px',
  },
  forceSelect: {
    '-webkit-user-select': 'all',
    '-moz-user-select': 'all',
    '-ms-user-select': 'all',
    'user-select': 'all',
    'word-break': 'break-all',
  },
};

const reportItems = [
  {
    id: 1,
    title: 'Spreadsheet formula',
    formulaGenerator: generators.generateSpreadsheetFormula,
    successMessage: 'Spreadsheet formula copied to clipboard',
    additionalDetails: <Filters />,
  },
  {
    id: 2,
    title: 'Tracking frequency analytics',
    formulaGenerator: generators.generateTrackingFrequencyAnalyticsFormula,
    successMessage: 'Tracking frequency analytics formula copied to clipboard',
  },
  {
    id: 3,
    title: 'Activities endpoint',
    formulaGenerator: generators.generateActivitiesEndpointUrl,
    successMessage: 'Activities endpoint copied to clipboard',
  },
  {
    id: 4,
    title: 'Activity types endpoint',
    formulaGenerator: generators.generateActivityTypesEndpointUrl,
    successMessage: 'Activity types endpoint copied to clipboard',
  },
];

class ReportTab extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      grayCard: PropTypes.string.isRequired,
      wrapper: PropTypes.string.isRequired,
      forceSelect: PropTypes.string.isRequired,
      buttonProgress: PropTypes.string.isRequired,
    }),
  };

  state = {
    loading: false,
    token: null,
  };

  getInfiniteToken = async () => {
    this.setState({
      loading: true,
    });
    const tokenData = await dataProvider(config.backend.url)('CUSTOM', 'infiniteToken', {
      method: 'GET',
    });
    this.setState({
      token: tokenData.token,
      loading: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, token } = this.state;

    return (
      <Card>
        <Alert type="warning">The token will disappear when you leave this page!</Alert>
        <Alert type="warning">{'Make sure that token is extra secured. Don\'t send it via email, Slack, etc.'}</Alert>
        <Title title="Reports" />
        <CardContent>
          <CardHeader title="Infinite Token"/>
          <Card className={classes.grayCard}>
            <Token loading={loading} generateTokenHandler={this.getInfiniteToken} token={token} classes={classes} />
          </Card>
        </CardContent>
        {reportItems.map(item => <ReportItem
          key={item.id}
          formulaGenerator={item.formulaGenerator}
          title={item.title}
          successMessage={item.successMessage}
          token={token}
          additionalDetails={item.additionalDetails}
        />)}
      </Card>
    );
  }
}

export default withStyles(styles)(ReportTab);
