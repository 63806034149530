import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const CopyButton = props => {
  const { copyHandler } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={copyHandler}
    >
      Copy to clipboard
    </Button>
  );
};

export default CopyButton;

CopyButton.propTypes = {
  copyHandler: PropTypes.func,
};
