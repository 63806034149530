import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles, Typography, withStyles } from '@material-ui/core';

import licensePropType from './license.propType';
import styles from './Copyrights.styles';

const useStyles = makeStyles(styles);

const CopyrightEntry = ({ license, showPreview }) => {
  const publisher = license.publisher || license.email;

  const classes = useStyles();

  return (
    <div className={classes.copyrightEntry}>
      <div>
        <span className={classes.copyrightEntryName}>{license.name}</span>
        {publisher && <Typography variant="caption" component="div">{publisher}</Typography>}
      </div>
      <div className={classes.horizontalSpacer} />

      <div className={classes.buttonsContainer}>
        {license.noticeContent && (
          <Button color="primary" onClick={() => showPreview(license.noticeContent)}>
            Notice
          </Button >
        )}

        {license.licenseContent && (
          <Button color="primary" onClick={() => showPreview(license.licenseContent)}>
            License
          </Button >
        )}
      </div>
    </div>
  );
};

CopyrightEntry.propTypes = {
  license: licensePropType.isRequired,
  showPreview: PropTypes.func.isRequired,
};

export default withStyles(styles)(CopyrightEntry);
