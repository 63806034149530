import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  DateField,
} from 'react-admin';

const IpBoxConfigurationList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <DateField label="Read-only IP Box before" source="readonlyBeforeDate" options={{ year: 'numeric', month: '2-digit' }} />
      <EditButton />
    </Datagrid>
  </List>
);

export default IpBoxConfigurationList;
