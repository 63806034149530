import { email } from 'react-admin';

const emailListValidation = () => {
  const emailValidator = email();

  return (emailString, values, props) => {
    const res = emailString.reduce((error, emailValue) => error || emailValidator(emailValue, values, props), '');

    return res;
  };
};


export default emailListValidation;
