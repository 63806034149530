/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  EditButton,
  DateField,
  DeleteButton,
} from 'react-admin';

import CustomPagination from '../common/CustomPagination';


export default class UserList extends React.Component {
  render() {
    return (
      <List {...this.props} pagination={<CustomPagination />} sort={{ field: 'user', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid>
          <FunctionField label="user" render={entry => `${entry.user.firstname} ${entry.user.lastname}`} />
          <DateField source="date" label="Opened Month" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    );
  }
}
