import {
  MODAL_HIDE,
  MODAL_SHOW,
} from '../constants/action-types';

export const showModal = (type, title, body, modalKey) => ({
  type: MODAL_SHOW,
  payload: {
    type,
    title,
    body,
    modalKey,
  },
});

export const hideModal = () => ({
  type: MODAL_HIDE,
});
