import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Token = ({ generateTokenHandler, loading, token, classes }) => {
  return (
    <div className={classes.wrapper}>
      {token
        ? <p className={classes.forceSelect}>{token}</p>
        : (
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={generateTokenHandler}
            className={'hidden'}
          >
            Generate token
          </Button>
        )}
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default Token;

Token.propTypes = {
  generateTokenHandler: PropTypes.func,
  loading: PropTypes.bool,
  token: PropTypes.string,
  classes: PropTypes.objectOf({
    grayCard: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    forceSelect: PropTypes.string.isRequired,
    buttonProgress: PropTypes.string.isRequired,
  }),
};
