import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { createPortal } from 'react-dom';

import { LAYOUT_ID } from '../../constants/element-selectors';

import styles from './Footer.styles';

const useStyles = makeStyles(styles);

const Footer = ({ children }) => {
  const [height, setHeight] = useState(0);
  const footerRef = useRef(null);

  useEffect(() => {
    const footerHeight = footerRef.current.clientHeight;
    setHeight(footerHeight);
  }, []);

  const classes = useStyles();

  const renderLayoutPlaceholder = () => {
    // the footer is fixed so we're rendering a placeholder that makes the footer not covering the layout

    const container = document.getElementById(LAYOUT_ID);

    if (!container) {
      return null;
    }

    const placeholder = <div className={classes.footerPlaceholder} style={{ height }} />;

    return createPortal(placeholder, container);
  };

  return (
    <>
      {renderLayoutPlaceholder()}
      <div ref={footerRef} className={classes.footerContainer}>
        <div className={classes.footerInner}>
          {children}
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
