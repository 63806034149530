import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/LoginForm';

import { GoogleLogin } from './GoogleLogin';
import { DevLogin } from './DevLogin';

const Form = ({ classes }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

  const googleAuthLogin = (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin classes={classes} setErrorMessage={setErrorMessage} />
    </GoogleOAuthProvider>
  );

  return (
    <div className={classes.container}>
      {process.env.NODE_ENV === 'development'
        ? <DevLogin classes={classes} setErrorMessage={setErrorMessage} />
        : googleAuthLogin}
      {errorMessage && (
        <div>
          <p className={classes.errorMessage}>{errorMessage}</p>
        </div>
      )}
    </div>);
};

Form.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    form: PropTypes.string,
    input: PropTypes.string,
    spinnerContainer: PropTypes.string,
    container: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
};


const enhance = compose(
  withStyles(styles),
);

export const LoginForm = enhance(Form);
