import config from '../../config';

const generateActivitiesEndpointUrl = token => {
  const { url } = config.backend;
  // eslint-disable-next-line no-credentials/no-credentials
  return token && `${url}/activities?jwt=${token}`;
};

const generateActivityTypesEndpointUrl = token => {
  const { url } = config.backend;
  // eslint-disable-next-line no-credentials/no-credentials
  return token && `${url}/activity-types?jwt=${token}`;
};

const generateSpreadsheetFormula = token => {
  const { url } = config.backend;
  // eslint-disable-next-line no-credentials/no-credentials
  return token && `=importxml("${url}/users-tracks?xml=1&jwt=${token}", "/tracks//track")`;
};


const generateTrackingFrequencyAnalyticsFormula = token => {
  const { url } = config.backend;
  // eslint-disable-next-line no-credentials/no-credentials
  return token && `=importxml("${url}/analytics/tracking-frequency?monthsToShowCount=3&xml=1&jwt=${token}", "/tracks//track")`;
};


export default {
  generateActivitiesEndpointUrl,
  generateActivityTypesEndpointUrl,
  generateSpreadsheetFormula,
  generateTrackingFrequencyAnalyticsFormula,
};
