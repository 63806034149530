import React from 'react';

import Alert from '../common/Alert';

const FiltersInfo = () => {
  return (
    <Alert type="info">
      <div>
        <h4>Query parameters to filter data:</h4>
        <p><b>user</b> - insert id or email</p>
        <p><b>status</b> - choose one of two options: in progress / done</p>
        <p><b>project</b> - insert project name</p>
        <p><b>from</b> - use format YYYY-MM-DD</p>
        <p><b>to</b> - use format YYYY-MM-DD</p>
        <p><b>month</b> - use format YYYY-MM</p>
        <p><b>last</b> - choose one of options: day/month/quarter/year</p>
        <h4>Note:</h4>
        <p>Parameter <b>month</b> is ignored if is used <b>from</b> or <b>to</b></p>
        <p>Parameter <b>last</b> is ignored if is used <b>month</b> or <b>from</b> or <b>to</b></p>
      </div>
    </Alert>
  );
};

export default FiltersInfo;
