import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { writeText } from 'clipboard-polyfill/build/clipboard-polyfill.promise';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Alert from '../common/Alert';
import CopyButton from '../common/CopyButton';


const styles = {
  grayCard: {
    background: '#f3f3f3',
    padding: '20px',
  },
  forceSelect: {
    '-webkit-user-select': 'all',
    '-moz-user-select': 'all',
    '-ms-user-select': 'all',
    'user-select': 'all',
    'word-break': 'break-all',
  },
};

const copyToClipboardStates = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const ALERT_TIMEOUT = 2000;

const ReportItem = ({ formulaGenerator, title, token, classes, successMessage, additionalDetails }) => {
  const [copyingStatus, setCopyingStatus] = useState(null);

  const formula = useMemo(() => formulaGenerator(token), [token]);

  const handleCopyFormula = useCallback(() => {
    writeText(formula)
      .then(() => {
        setCopyingStatus(copyToClipboardStates.SUCCESS);

        setTimeout(() => {
          setCopyingStatus(null);
        }, ALERT_TIMEOUT);
      })
      .catch(() => setCopyingStatus(copyToClipboardStates.ERROR));
  }, [copyingStatus, setCopyingStatus, formula]);

  return (
    <CardContent>
      <CardHeader title={title} />
      <Card
        style={{
          background: '#f3f3f3',
          padding: '20px',
        }}
      >
        {formula ? (
          <span>
            <p className={classes.forceSelect}>{formula}</p>
            {!copyingStatus && (
              <CopyButton
                copyHandler={handleCopyFormula}
              />
            )}
            {copyingStatus === copyToClipboardStates.SUCCESS
              ? <Alert type="success">{successMessage}</Alert>
              : null
            }
            {copyingStatus === copyToClipboardStates.ERROR
              ? <Alert type="warning">Copying to clipboard went wrong. Please copy the url by hand.</Alert>
              : null
            }
            {additionalDetails}
          </span>
        ) : 'You need to generate the token first!'}
      </Card>
    </CardContent>
  );
};

export default withStyles(styles)(ReportItem);

ReportItem.propTypes = {
  title: PropTypes.string,
  formulaGenerator: PropTypes.func,
  token: PropTypes.string,
  classes: PropTypes.shape({
    grayCard: PropTypes.string.isRequired,
    forceSelect: PropTypes.string.isRequired,
  }),
  successMessage: PropTypes.string,
  additionalDetails: PropTypes.node,
};
