const colors = [
  '#E27D86',
  '#E7686E',
  '#C75255',
  '#E25242',
  '#E33347',
  '#ED99B1',
  '#D7889E',
  '#915B6B',
  '#CC917E',
  '#BE7081',
  '#C189E1',
  '#902174',
  '#972B68',
  '#6B1C53',
  '#B56B8C',
  '#E19BF6',
  '#E53ABB',
  '#C7538F',
  '#C7538F',
  '#C73561',
  '#8E449C',
  '#C46CC1',
  '#A576C1',
  '#9861DF',
  '#8232A3',
  '#8536DA',
  '#5D2F7E',
  '#7A6ACB',
  '#695984',
  '#916990',
  '#8271B4',
  '#A294F4',
  '#879FDF',
  '#AAABCD',
  '#8396BF',
  '#97B2ED',
  '#6C9FE4',
  '#4050AA',
  '#1E5EE0',
  '#214C85',
  '#757295',
  '#54627A',
  '#5A5D85',
  '#525163',
  '#4E404F',
  '#223254',
  '#3F3D5B',
  '#081963',
  '#9BC9F4',
  '#96DCE8',
  '#5FC4D7',
  '#324B51',
  '#617C7A',
  '#609BA2',
  '#7DA4B8',
  '#8CA7A2',
  '#B4C2C4',
  '#82BFAC',
  '#58BCA0',
  '#6AB4AB',
  '#A2CED4',
  '#507553',
  '#295A61',
  '#347769',
  '#649384',
  '#6EDFC1',
  '#469659',
  '#83AB6C',
  '#6CBF6B',
  '#92D495',
  '#B0C74B',
  '#98AB4B',
  '#D0DC59',
  '#CAC141',
  '#F8CF6C',
  '#F3A83C',
  '#D29A51',
  '#EE8251',
  '#E8763E',
  '#A9521F',
  '#854039',
  '#BEABAA',
  '#BBA793',
  '#A49B81',
  '#947A7A',
  '#8A6256',
  '#BB685B',
  '#A9815B',
  '#7E6763',
  '#696256',
  '#442A32',
  '#A1A5B2',
];

export default colors;
