import React from 'react';
import { Edit, TextInput, BooleanInput, SimpleForm, RadioButtonGroupInput } from 'react-admin';

import ColorPickerInput from '../ColorPickerInput';
import PostShowActions from '../common/UpdateToolbar';
import { validateName, validateKey, validateType } from '../../helpers/validators/activity';

const ActivityEdit = props => (
  <Edit title="Edit activity" {...props}>
    <SimpleForm toolbar={<PostShowActions />} redirect="list">
      <TextInput source="name" validate={validateName} />
      <TextInput label="Short Name" source="key" validate={validateKey} />
      <BooleanInput label="Active" source="isActive" />
      <RadioButtonGroupInput
        validate={validateType}
        source="type"
        choices={[
          { id: 'project', name: 'Project' },
          { id: 'internalProject', name: 'Internal Project' },
          { id: 'timeOff', name: 'Time Off' },
        ]}
      />
      <ColorPickerInput />
    </SimpleForm>
  </Edit>
);

export default ActivityEdit;
