import React, { Component } from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, BooleanField, DateField } from 'react-admin';

class PublicHolidayList extends Component {
  render() {
    return (
      <List {...this.props} title="Public Holidays List" sort={{ field: 'date', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid>
          <DateField source="date" />
          <TextField source="description" />
          <BooleanField source="repeatEveryYear" label="Repeat every year" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    );
  }
}

export default PublicHolidayList;
