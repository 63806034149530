import React, { useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import { fetchUtils } from 'react-admin';

import Copyrights from './Copyrights';

// the file is empty by default and gets populated by a CI job
const LICENSES_URL = '/licensesForAttribution.json';

const CopyrightsContainer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    fetchUtils.fetchJson(LICENSES_URL)
      .then(res => res.json)
      .then(setLicenses);
  }, []);

  const onCopyrightsClick = event => {
    event.preventDefault();
    showModal();
  };

  const getModal = () => {
    if (!isModalVisible) {
      return null;
    }

    const props = {
      isModalVisible: true,
      licenses,
      hideModal,
    };

    return <Copyrights {...props} />;
  };

  return (
    <>
      <Link
        href="#"
        variant="overline"
        underline="none"
        onClick={onCopyrightsClick}
        color="textPrimary"
      >
        Copyrights
      </Link>
      {getModal()}
    </>
  );
};

export default CopyrightsContainer;
