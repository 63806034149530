import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmModal extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func,
    confirm: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(React.Component),
    ]),
  };

  handleClose = () => {
    this.props.hideModal();
  };

  handleConfirm = () => {
    this.handleClose();
    this.props.confirm();
  };

  render() {
    return (
      <Dialog
        open
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmModal;
