import React from 'react';
import { Edit, TextInput, SimpleForm, NumberInput, number, minValue, maxValue } from 'react-admin';

import PostShowActions from '../common/UpdateToolbar';
import emailListValidation from '../../helpers/validators/reportAlertConfiguration';

// This is the latest day that all the months have.
// I.e, it corresponds to the last day of February in a common year.
// I.e, "every month in every year has 28 days, not every month in every year has 29 days"
const LAST_DAY_OF_THE_MONTH = 28;

const emailListToString = emailList => emailList.join(',');
const emailStringToList = emailString => emailString.replace(/ /g, '').split(',');

const ReportAlertConfigurationEdit = props => (
  <Edit title="Edit report alert configuration" {...props}>
    <SimpleForm toolbar={<PostShowActions />} redirect="list">
      <NumberInput source="reportDay" validate={[number(), minValue(0), maxValue(LAST_DAY_OF_THE_MONTH)]} />
      <TextInput label="Report Emails" source="reportEmails" format={emailListToString} parse={emailStringToList} validate={[emailListValidation()]} />
    </SimpleForm>
  </Edit>
);

export default ReportAlertConfigurationEdit;
