import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const LoadingSpinner = ({ classes }) => {
  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );
};

LoadingSpinner.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    form: PropTypes.string,
    input: PropTypes.string,
    spinnerContainer: PropTypes.string,
    container: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
};

export default LoadingSpinner;
