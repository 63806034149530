import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/deepOrange';
import blue from '@material-ui/core/colors/blue';

const BACKGROUND_WEIGHT = 100;
const BORDER_WEIGHT = 300;
const COLOR_WEIGHT = 900;

const styles = {
  alert: {
    padding: '15px',
    border: '1px solid transparent',
    margin: '20px',
    'margin-bottom': '20px',
    'border-radius': '3px',
    'min-width': '150px',
  },
  success: {
    'background-color': green[BACKGROUND_WEIGHT],
    'border-color': green[BORDER_WEIGHT],
    color: green[COLOR_WEIGHT],
  },
  warning: {
    'background-color': orange[BACKGROUND_WEIGHT],
    'border-color': orange[BORDER_WEIGHT],
    color: orange[COLOR_WEIGHT],
  },
  error: {
    'background-color': red[BACKGROUND_WEIGHT],
    'border-color': red[BORDER_WEIGHT],
    color: red[COLOR_WEIGHT],
  },
  info: {
    'background-color': blue[BACKGROUND_WEIGHT],
    'border-color': blue[BORDER_WEIGHT],
    color: blue[COLOR_WEIGHT],
  },
};

class Alert extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf([
      'success',
      'warning',
      'danger',
      'info',
    ]),
    classes: PropTypes.shape({
      alert: PropTypes.string.isRequired,
    }),
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  };

  render() {
    const { classes, type, children } = this.props;
    return (
      <div className={`${classes[type]} ${classes.alert}`}>{children}</div>
    );
  }
}

export default withStyles(styles)(Alert);
