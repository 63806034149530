import config from '../config';

const validateExternalTokenWeb = async (data, setErrorMessage, history, setIsLoading) => {
  fetch(`${config.backend.url}/validateToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      source: config.source,
    },
    body: JSON.stringify({
      code: data.code,
      email: data.email,
      appType: 'web',
    }),
  }).then(async response => {
    if (!response.ok) {
      setIsLoading(false);
      const err = await response.json();
      return setErrorMessage(err.message ?? 'Something went wrong. Please try again');
    }

    const jsonResponse = await response.json();
    localStorage.setItem('token', jsonResponse.token);
    localStorage.setItem('roles', jsonResponse.payload.roles);
    history.push('/activity');
  });
};

export default validateExternalTokenWeb;
