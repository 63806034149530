import {
  required,
  minLength,
  minValue,
  number,
  email,
} from 'react-admin';

import checkEmailAvailability from '../checkEmailAvailability';

const ensureEmailIsNotInUse = async (fields, dispatch, props) => {
  const email = fields.email;
  const currentEmail = props.initialValues.email;

  if (!email || currentEmail === email) {
    return {};
  }

  const available = await checkEmailAvailability(email);

  if (!available) {
    const emailError = {
      email: `${email} is already in use.`,
    };

    throw emailError;
  }

  return {};
};

const validateGroups = groups => {
  if (groups && groups.length > 0) {
    return false;
  }

  return `User has to have at least one group assigned!`;
};

const nameMinLength = 2;

const validateName = [required(), minLength(nameMinLength)];
const validateEmail = [required(), email()];
const validatePhoneNumber = [number()];

const expectedWorkTimeMin = 0;
const expectedWorkTimeDividesBy = 30;

const dividesBy = by => number => {
  return number % by && `The number should divide by ${by}`;
};

const validateExpectedWorkTime = [
  required(),
  number(),
  minValue(expectedWorkTimeMin),
  dividesBy(expectedWorkTimeDividesBy),
];
const validateDate = [required()];


export {
  validateName,
  validateEmail,
  validatePhoneNumber,
  validateExpectedWorkTime,
  validateDate,
  ensureEmailIsNotInUse,
  validateGroups,
};
