import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultTheme } from 'ra-ui-materialui';
import { withStyles } from '@material-ui/core/styles';

import { LoginForm } from '../components/login/LoginForm';
import styles from '../styles/Auth';
import LoginBox from '../components/LoginBox';

class LoginPage extends Component {
  static propTypes = {
    loginForm: PropTypes.element,
    classes: PropTypes.shape({
      avatar: PropTypes.string,
      card: PropTypes.string,
      icon: PropTypes.string,
      main: PropTypes.string,
    }),
  };

  render() {
    return (
      <LoginBox
        content={this.props.loginForm}
        classes={this.props.classes}
      />
    );
  }
}

LoginPage.defaultProps = {
  theme: defaultTheme,
  loginForm: <LoginForm />,
};

export default connect(null)(withStyles(styles)(LoginPage));
