import React from 'react';
import { format } from 'date-fns';
import { Create, SimpleForm, TextInput, DateInput, BooleanInput } from 'react-admin';

import { validateDate, validateDescription } from '../../helpers/validators/publicHoliday';

class PublicHolidayCreate extends React.Component {
  render() {
    return (
      <Create title="Create Public Holiday" {...this.props} >
        <SimpleForm redirect="list">
          <DateInput source="date" defaultValue={format(new Date(), 'yyyy-MM-dd')} validate={validateDate} />
          <TextInput source="description" validate={validateDescription}/>
          <BooleanInput source="repeatEveryYear" label="Repeat every year" />
        </SimpleForm>
      </Create>
    );
  }
}

export default PublicHolidayCreate;
