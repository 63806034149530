import camelCase from 'lodash.camelcase';
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';

import config from '../config';

export default apiUrl => {
  const httpClient = (apiUrl, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('jwt', localStorage.getItem('token'));
    options.headers.set('source', config.source);

    return fetchUtils.fetchJson(apiUrl, options);
  };

  const convertDataRequestToHTTP = (type, resource, params) => {
    const options = {};
    let url;

    if (type === 'LOGIN' || type === 'CUSTOM') {
      url = `${apiUrl}/${resource}`;
      options.method = params.method || 'POST';
      options.body = JSON.stringify(params.data);
    }

    return { url, options };
  };

  const convertHTTPResponse = response => {
    return response.json;
  };


  return (type, resource, params) => {
    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    if (type === 'LOGIN' || type === 'CUSTOM') {
      return httpClient(url, options).then(response => {
        return convertHTTPResponse(response, type, resource, params);
      });
    }

    return jsonServerProvider(apiUrl, httpClient)[camelCase(type)](resource, params);
  };
};
