import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactGoogleButton from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';

import validateExternalTokenWeb from '../../helpers/validateExternalToken';

import LoadingSpinner from './LoadingSpinner';

const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
];

export const GoogleLogin = ({ setErrorMessage, classes }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const successLoginHandler = async data => {
    setIsLoading(true);
    validateExternalTokenWeb({ code: data.code }, setErrorMessage, history, setIsLoading);
  };

  const errorLoginHandler = () => {
    setErrorMessage('Error occured when trying to log in with external provider. Please try again');
  };

  const login = useGoogleLogin({
    scope: scopes,
    onSuccess: successLoginHandler,
    onError: errorLoginHandler,
    flow: 'auth-code',
  });

  return (
    <>
      <ReactGoogleButton
        onClick={login}
      />
      {isLoading && <LoadingSpinner classes={classes}/>}
    </>
  );
};


GoogleLogin.propTypes = {
  setErrorMessage: PropTypes.func,
  classes: PropTypes.shape({
    button: PropTypes.string,
    form: PropTypes.string,
    input: PropTypes.string,
    spinnerContainer: PropTypes.string,
    container: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
};
