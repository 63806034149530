import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, Typography, withStyles, makeStyles } from '@material-ui/core';

import licensePropType from './license.propType';
import styles from './Copyrights.styles';
import CopyrightEntry from './CopyrightEntry';

const useStyles = makeStyles(styles);

const Copyrights = ({ isModalVisible, hideModal, licenses }) => {
  const dialogContentRef = useRef();
  const scrollToTop = () => {
    if (!dialogContentRef?.current) {
      return;
    }
    dialogContentRef.current.scrollTop = 0;
  };

  const [previewContent, setPreviewContent] = useState('');

  const resetPreviewContent = () => setPreviewContent('');

  useEffect(scrollToTop, [previewContent]);

  const classes = useStyles();

  const getContent = () => {
    if (previewContent) {
      return (
        <div className={classes.contentPreview}>{previewContent}</div>
      );
    }

    return (
      <>
        <div>Attributions:</div>
        {licenses.map((license, i) => <CopyrightEntry key={i} license={license} showPreview={setPreviewContent} />)}
      </>
    );
  };

  return (
    <Dialog open={isModalVisible} onClose={hideModal} disableScrollLock>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h6">Copyrights</Typography>

        <div className={classes.horizontalSpacer} />

        {previewContent && (
          <Button color="primary" onClick={resetPreviewContent}>
            ← Back
          </Button >
        )}
      </DialogTitle>
      <DialogContent ref={dialogContentRef}>{getContent()}</DialogContent>
    </Dialog>
  );
};

Copyrights.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  licenses: PropTypes.arrayOf(licensePropType).isRequired,
};

export default withStyles(styles)(Copyrights);
