export default () => ({
  container: {
    padding: '0 0 1em 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
  errorMessage: {
    maxWidth: '200px',
    color: 'red',
  },
  spinnerContainer: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.5)',
  },
});
