import { UNAUTHORIZED, FORBIDDEN } from 'http-status-codes';

export default dataProvider => ({
  // authentication
  login: params => {
    return dataProvider(
      'LOGIN',
      'validateToken',
      {
        data: {
          email: params.email,
          token: params.code,
        },
      })
      .then(result => {
        localStorage.setItem('token', result.token);
        localStorage.setItem('roles', result.payload.roles);
      });
  },
  checkError: error => {
    const { status } = error;
    if (status === UNAUTHORIZED || status === FORBIDDEN) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: () => {
    const roles = localStorage.getItem('roles') || [];
    return Promise.resolve(roles);
  },
});
