import React from 'react';
import { format } from 'date-fns';
import { Edit, SimpleForm, TextInput, DateInput, BooleanInput } from 'react-admin';

import UpdateToolbar from '../common/UpdateToolbar';
import { validateDate } from '../../helpers/validators/publicHoliday';

const PublicHolidayEdit = props => (
  <Edit title="Edit Public Holiday" {...props}>
    <SimpleForm redirect="list" toolbar={<UpdateToolbar />}>
      <DateInput source="date" defaultValue={format(new Date(), 'yyyy-MM-dd')} validate={validateDate} />
      <TextInput source="description" />
      <BooleanInput source="repeatEveryYear" label="Repeat every year" />
    </SimpleForm>
  </Edit>
);

export default PublicHolidayEdit;
