import React, { PureComponent } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
} from 'react-admin';
import PropTypes from 'prop-types';

import UpdateToolbar from '../common/UpdateToolbar';
import CheckboxGroup from '../common/CheckboxGroup';
import config from '../../config';
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
  validateExpectedWorkTime,
  validateDate,
  ensureEmailIsNotInUse,
  validateGroups,
} from '../../helpers/validators/user';

import UserEditActions from './UserEditActions';

class UserEdit extends PureComponent {
  static propTypes = {
    changeForm: PropTypes.func,
  };

  render() {
    const newProps = { ...this.props };
    delete newProps.changeForm;
    return (
      <Edit title="Edit User" actions={<UserEditActions/>} {...newProps}>
        <SimpleForm
          redirect="list"
          toolbar={<UpdateToolbar/>}
          asyncValidate={ensureEmailIsNotInUse}
        >
          <TextInput label="First name" source="firstname" validate={validateName} />
          <TextInput label="Last name" source="lastname" validate={validateName} />
          <TextInput source="email" validate={validateEmail} />
          <TextInput source="phone" validate={validatePhoneNumber} />
          <NumberInput defaultValue={0} label="Expected work time (minutes / day)" source="expectedWorkTime" validate={validateExpectedWorkTime} />
          <TextInput source="location" />
          <DateInput source="firstReportingDate" validate={validateDate} defaultValue={new Date()} />
          <ReferenceArrayInput reference="userGroup" source="groups" label="Groups" validate={validateGroups}>
            <CheckboxGroup forceSelect={{ selected: config.roles.admin, force: config.roles.user }}/>
          </ReferenceArrayInput>
          <BooleanInput label="Active" source="isActive" />
        </SimpleForm>
      </Edit>
    );
  }
}

export default UserEdit;
