import React, { Component } from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

class UserGroupList extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <List {...this.props} title="User Groups List" sort={{ field: 'name', order: 'DESC' }} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="name" />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}

export default UserGroupList;
