import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';

import UpdateToolbar from '../common/UpdateToolbar';
import {
  validateName,
} from '../../helpers/validators/userGroup';

const UserGroupEdit = props => (
  <Edit title="Edit User Group" {...props}>
    <SimpleForm
      redirect="list"
      toolbar={<UpdateToolbar />}
    >
      <TextInput source="name" validate={validateName} />
    </SimpleForm>
  </Edit>
);

export default UserGroupEdit;
