import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

import {
  validateName,
} from '../../helpers/validators/userGroup';

export default class UserGroupCreate extends React.Component {
  render() {
    return (
      <Create title="Create User Group" {...this.props} >
        <SimpleForm redirect="list">
          <TextInput source="name" validate={validateName} />
        </SimpleForm>
      </Create>
    );
  }
}
