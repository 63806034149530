import { required, maxLength } from 'react-admin';

const descriptionMaxLength = 100;

const validateDate = [required()];
const validateDescription = [maxLength(descriptionMaxLength)];

export {
  validateDate,
  validateDescription,
};
