import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { CheckboxGroupInput } from 'react-admin';

import { validateGroups } from '../../helpers/validators/user';

const CheckboxGroup = props => {
  const handleChange = selected => {
    props.input.onChange(selected);
    if (props.forceSelect && selected.includes(props.forceSelect.selected)) {
      props.changeForm(
        'groups',
        [...new Set([...selected, props.forceSelect.force])],
      );
    }
  };

  const { ...newProps } = props;
  delete newProps.forceSelect;
  delete newProps.changeForm;

  const error = validateGroups(newProps.input.value);
  if (error && (props.meta.submitFailed || props.meta.initial)) {
    newProps.error = true;
    newProps.meta.helperText = error;
    newProps.meta.error = false;
  }
  return <CheckboxGroupInput
    {...newProps}
    input={{ ...newProps.input, onChange: handleChange }}
    style={{ width: '100%' }}
  />;
};

CheckboxGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    initial: PropTypes.array,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  changeForm: PropTypes.func,
  forceSelect: PropTypes.shape({
    selected: PropTypes.number,
    force: PropTypes.number,
  }),
};

export default connect(
  null,
  dispatch => ({
    changeForm: (field, value) => dispatch(change('record-form', field, value)),
  }),
)(CheckboxGroup);
