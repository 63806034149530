import React from 'react';
import PropTypes from 'prop-types';


const ReportEmailsField = ({ record }) => <>{record.reportEmails.join(', ')}</>;

ReportEmailsField.propTypes = {
  record: PropTypes.shape({
    reportEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default ReportEmailsField;
