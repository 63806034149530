import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import en from './en';

const messages = {
  en: {
    ...englishMessages,
    ...en,
  },
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale]);
