import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { isAdmin } from './helpers/roles';
import authProviderCreator from './providers/authProvider';
import dataProviderCreator from './providers/dataProvider';
import { i18nProvider } from './lang';
import LoginPage from './containers/LoginPage';
import ReportTab from './components/report/ReportTab';
import MyLayout from './components/layout/Layout';
// Dashboard is hidden intentionally - for more info check AN-249
// import Dashboard from './containers/Dashboard';
import modalReducer from './reducers/modalReducer';
import ActivityList from './components/activity/ActivityList';
import ActivityEdit from './components/activity/ActivityEdit';
import ActivityCreate from './components/activity/ActivityCreate';
import UserList from './components/user/UserList';
import UserEdit from './components/user/UserEdit';
import UserCreate from './components/user/UserCreate';
import UserGroupList from './components/userGroup/UserGroupList';
import UserGroupEdit from './components/userGroup/UserGroupEdit';
import UserGroupCreate from './components/userGroup/UserGroupCreate';
import PublicHolidayList from './components/publicHoliday/PublicHolidayList';
import PublicHolidayEdit from './components/publicHoliday/PublicHolidayEdit';
import PublicHolidayCreate from './components/publicHoliday/PublicHolidayCreate';
import ReportAlertConfigurationList from './components/reportAlertConfiguration/ReportAlertConfigurationList';
import ReportAlertConfigurationEdit from './components/reportAlertConfiguration/ReportAlertConfigurationEdit';
import ReopenMonthList from './components/reopenMonth/ReopenMonthList';
import ReopenMonthEdit from './components/reopenMonth/ReopenMonthEdit';
import ReopenMonthCreate from './components/reopenMonth/ReopenMonthCreate';
import InfiniteTokenList from './components/infiniteToken/InfiniteTokenList';
import IpBoxConfigurationList from './components/ipBoxConfiguration/IpBoxConfigurationList';
import IpBoxConfigurationEdit from './components/ipBoxConfiguration/ipBoxConfigurationEdit';
import config from './config';
import './styles/App.css';
import CopyrightsContainer from './containers/Copyrights';
import Footer from './components/footer';

export const dataProvider = dataProviderCreator(config.backend.url);
const authProvider = authProviderCreator(dataProvider);

const reducers = {
  modal: modalReducer,
};

class App extends Component {
  checkPermissions = roles => {
    if (!isAdmin(roles)) {
      // Fix for (probably) react-admin bug - Admin has to have at least one resource
      return [<Resource key="login" name="login" />];
    }

    return [
      <Resource key="activity" name="activity" list={ActivityList} edit={ActivityEdit} create={ActivityCreate} />,
      <Resource key="user" name="user" list={UserList} edit={UserEdit} create={UserCreate} />,
      <Resource key="userGroup" name="userGroup" options={{ label: 'User Groups' }} list={UserGroupList} edit={UserGroupEdit} create={UserGroupCreate} />,
      <Resource key="activityGroup" name="activityGroup" options={{ label: 'Activity Groups', show: false }} />,
      <Resource key="publicHoliday" name="publicHoliday" options={{ label: 'Public Holidays' }} list={PublicHolidayList} edit={PublicHolidayEdit} create={PublicHolidayCreate} />,
      <Resource key="reportAlertConfiguration" name="reportAlertConfiguration" options={{ label: 'Report Alert Configuration' }} list={ReportAlertConfigurationList} edit={ReportAlertConfigurationEdit}/>,
      <Resource key="reopenMonth" name="reopenMonth" options={{ label: 'Reopen Month for User' }} list={ReopenMonthList} edit={ReopenMonthEdit} create={ReopenMonthCreate}/>,
      <Resource key="ipBoxConfiguration" name="ipBoxConfiguration" options={{ label: 'IP Box Configuration' }} list={IpBoxConfigurationList} edit={IpBoxConfigurationEdit} />,
      <Resource key="infiniteTokens" name="infiniteTokens" options={{ label: 'Infinite Tokens' }} list={InfiniteTokenList}/>,
    ];
  };

  render() {
    return (
      <>
        <Admin
          title="Anthill Administration"
          locale="en"
          appLayout={MyLayout}
          i18nProvider={i18nProvider}
          // dashboard={Dashboard}
          authProvider={authProvider}
          dataProvider={dataProvider}
          loginPage={LoginPage}
          customReducers={reducers}
          customRoutes={[
            <Route key={2} path="/reports" component={ReportTab}/>,
          ]}
        >
          {this.checkPermissions}
        </Admin>
        <Footer>
          <CopyrightsContainer />
        </Footer>
      </>
    );
  }
}

export default App;
