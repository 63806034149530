import React from 'react';
import { Field } from 'react-final-form';

import './ColorPickerInput.css';
import colors from '../constants/colors';

const ColorPickerInput = () => (
  <>
    <label className="ColorPickerInput__label">Color</label>
    <div className="ColorPickerInput__wrapper">{
      colors.map(color =>
        <div className="ColorPickerInput__input" key={color} style={{ backgroundColor: color }}>
          <Field
            name="color"
            component="input"
            type="radio"
            value={color}
            id={color}
          />
        </div>,
      )
    }
    </div>
  </>
);

export default ColorPickerInput;
