import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { Lock } from '@material-ui/icons';
import { Notification } from 'ra-ui-materialui';

class LoginBox extends Component {
  static propTypes = {
    content: PropTypes.element,
    className: PropTypes.string,
    classes: PropTypes.shape({
      main: PropTypes.string,
      card: PropTypes.string,
      avatar: PropTypes.string,
      icon: PropTypes.string,
    }).isRequired,
  };

  render() {
    return (
      <div
        className={classnames(this.props.classes.main, this.props.className)}
      >
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.avatar}>
            <Avatar className={this.props.classes.icon}>
              <Lock />
            </Avatar>
          </div>
          {this.props.content}
        </Card>
        <Notification />
      </div>
    );
  }
}

export default LoginBox;
