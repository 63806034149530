export default {
  backend: {
    url: process.env.REACT_APP_BACKEND_URL,
  },
  roles: {
    admin: 2,
    user: 1,
  },
  // eslint-disable-next-line no-credentials/no-credentials
  source: '4B5E57F6EB2F42B9039B3D1E13929295F231749C510CBE341CD68036D9AF97E2',
  validateCodeUrl: '/validate-code/:code/:email',
};
